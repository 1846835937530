import { Button } from '@doit/pcnt-react-ui-library';
import {
  Box, Grid, Typography, Dialog,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getLoanRequest, getLoanStatus, getDisbursementStatus, dismiss, getSurvey,
} from '../api/onboardingService';
import '../assets/css/onboardingFinal.css';
import { getEnv, CREDICUOTAS_WEBSITE_URL, SELF_MANAGEMENT_URL } from '../api/env';
import cancelCreditImg from '../assets/imgs/cancel-credit.svg';
import i18n from '../common/i18n';
import SimpleFooter from '../components/SimpleFooter';
import Toast from '../components/commons/Toast';
import {
  getResponseErrorOrDefault, sendDataLayerCustomerInfo, sendDataLayerEventObject, fillMessageWith, isMobileOperatingSystem,
} from '../utils/functionsUtil';
import { hasPermissionGrantedOrDenied, requestPermission } from '../utils/firebase';
import OnboardingFinalAutomaticFailed from './onboardingFinalAutomaticFailed';
import OnboardingFinalAutomaticPending from './onboardingFinalAutomaticPending';
import OnboardingFinalAutomaticSuccess from './onboardingFinalAutomaticSuccess';
import OnboardingFinalManual from './onboardingFinalManual';
import OnboardingFinalSucursal from './onboardingFinalSucursal';
import OnboardingFinalWelcomeAutomatic from './onboardingFinalWelcomeAutomatic';
import OnboardingFinalEcommerce from './onboardingFinalEcommerce';
import OnboardingFinalDismiss from './onboardingFinalDismiss';
import NotificationSubscription from '../components/NotificationSubscription';
import NpsForm from '../components/NpsForm';
import { isLoanRequestRevolvingLine } from '../utils/loanRequestUtils';
import OnboardingFinalRevolvingLine from '../components/OnboardingFinalRevolvingLine';

const OnboardingFinal = () => {
  const { hash } = useParams();
  const [loanRequest, setLoanRequest] = useState();
  const [ecommerceLoan, setEcommerceLoan] = useState(false);
  const [rechargeLoan, setRechargeLoan] = useState(false);
  const [servicePayLoan, setServicePay] = useState(false);
  const [branchCashLoan, setBranchCashLoan] = useState(false);
  const [productAttributes, setProductAttributes] = useState(false);
  const [showDismissConfirmation, setShowDismissConfirmation] = useState(false);
  const [notifyDismissActionMessage, setNotifyDismissActionMessage] = useState(false);
  const [allowDismiss, setAllowDismiss] = useState(false);
  const [disbursementStatus, setDisbursementStatus] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [startCountDown, setStartCountDown] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState({});
  const notificationsPopUpDelay = 3000;
  const npsPopUpDelay = 6000;
  const [showPushNotification, setShowPushNotification] = useState(false);
  const CUSTOME_MESSAGE_PUSH_NOTIFICATION_LIST = ['5', '6', '7', '8', '9'];

  const getPushNotificationMessage = () => {
    let message = i18n.NotificationSubscription.messageForLoansNews;
    const dniLastDigit = loanRequest.customerId.slice(-2, -1);
    if (CUSTOME_MESSAGE_PUSH_NOTIFICATION_LIST.includes(dniLastDigit)) {
      message = i18n.NotificationSubscription.messageForSomeDNIs;
    }
    return message;
  };

  const handleCancelSubscription = () => {
    setShowPushNotification(false);
  };

  const handleSubscribe = () => {
    setShowPushNotification(false);
    requestPermission(hash);
  };

  const goToLendingSelfie = async () => {
    window.location.href = getEnv(SELF_MANAGEMENT_URL);
  };

  const goToSite = async () => {
    sendDataLayerEventObject({ event: 'volver_inicio_pantalla_fin' });
    window.location.href = getEnv(CREDICUOTAS_WEBSITE_URL);
  };

  const onCloseSurvey = () => {
    setShowSurvey(false);
    if (loanRequest.productToPay?.ecommerceCartType) {
      setStartCountDown(true);
    }
  };

  const dismissLoan = async () => {
    try {
      await dismiss(hash);
      setAllowDismiss(false);
      setNotifyDismissActionMessage(true);
      setShowDismissConfirmation(false);
    } catch (error) {
      setErrorMessage(i18n.OnboardingFinal.dismissError);
      setShowDismissConfirmation(false);
    }
  };

  const openNotifications = (lr, disburseStatusResponseData) => {
    try {
      if (isMobileOperatingSystem() && !hasPermissionGrantedOrDenied()) {
        if (!lr.saleCompletionAutomatic
          || (!disburseStatusResponseData?.pending && !disburseStatusResponseData?.failed)) {
          setTimeout(() => {
            setShowPushNotification(true);
          }, notificationsPopUpDelay);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openNps = (lr) => {
    try {
      setTimeout(async () => {
        const surveyResponse = await getSurvey();
        if (surveyResponse && surveyResponse.data !== '') {
          setShowSurvey(true);
          setSurveyInfo(surveyResponse.data);
        } else if (lr.productToPay?.ecommerceCartType) {
          setStartCountDown(true);
        }
      }, npsPopUpDelay);
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      const lr = loanRequestResponse.data;
      if (lr) {
        sendDataLayerCustomerInfo(lr);
        setLoanRequest(lr);
        setEcommerceLoan(lr.productToPay?.ecommerceCartType);
        setRechargeLoan(lr.productToPay?.rechargeType);
        setServicePay(lr.productToPay?.billPaymentType);
        setBranchCashLoan(lr.commerceId === 99);
        if (lr.productToPay && lr.productToPay.products
          && lr.productToPay.products.length > 0
          && lr.productToPay.products[0].productAttributes) {
          setProductAttributes(lr.productToPay.products[0].productAttributes);
        }
        const loanStatusResponse = await getLoanStatus(hash);
        const loanStatus = loanStatusResponse.data.status;
        if (loanStatus === 'DES') {
          setNotifyDismissActionMessage(true);
        } else if (lr.saleCompletionManual && (loanStatus === 'PRE' || loanStatus === 'PEN')) {
          setAllowDismiss(true);
          setNotifyDismissActionMessage(false);
        }
        const disburseStatusResponse = await getDisbursementStatus(hash);
        setDisbursementStatus(disburseStatusResponse.data);
        openNotifications(lr, disburseStatusResponse.data);
        openNps(lr);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Box className="main-container-body">
        <Grid container className="onboarding-final-main-container">

          {loanRequest && !notifyDismissActionMessage && !showDismissConfirmation && (
            <>

              {/* PopUp mensaje de subscripcion a notificaciones */}
              {showPushNotification && !showSurvey && (
                <NotificationSubscription
                  onCancel={handleCancelSubscription}
                  onSubscribe={handleSubscribe}
                  message={getPushNotificationMessage()}
                  positionBottom
                />
              )}

              {/* ********** REVOLVING LINE ********** */}
              {isLoanRequestRevolvingLine(loanRequest) ? (
                <>
                  <Grid item xs={12}>
                    <OnboardingFinalRevolvingLine loanRequest={loanRequest} />
                  </Grid>
                </>
              ) : (
                <>
                  {/* ********** ECOMMERCE ********** */}
                  {ecommerceLoan && (
                  <>
                    <Grid item xs={12}>
                      <OnboardingFinalEcommerce loanRequest={loanRequest} startCountDown={startCountDown} />
                    </Grid>
                  </>
                  )}

                  {/* ********** EFECTIVO SUCURSAL ********** */}
                  {!ecommerceLoan && branchCashLoan && (
                  <>
                    <Grid item xs={12} sm={8}>
                      <OnboardingFinalSucursal />
                    </Grid>
                  </>
                  )}

                  {!ecommerceLoan && !branchCashLoan && (
                  <>
                    <Grid item xs={12} sm={8}>
                      {/* ********** MANUAL ********** */}
                      {loanRequest.saleCompletionManual && (
                      <>
                        <OnboardingFinalManual loanRequest={loanRequest} />
                      </>
                      )}

                      {/* ********** BIENVENIDA AUTOMATICA ********** */}
                      {loanRequest.saleCompletionWelcomeAutomatic && (
                      <>
                        <OnboardingFinalWelcomeAutomatic loanRequest={loanRequest} />
                      </>
                      )}

                      {/* ********** AUTOMATICO (SUCCESS) ********** */}
                      {loanRequest.saleCompletionAutomatic && disbursementStatus?.success && (
                      <>
                        <OnboardingFinalAutomaticSuccess
                          loanRequest={loanRequest}
                          rechargeLoan={rechargeLoan}
                          servicePayLoan={servicePayLoan}
                          productAttributes={productAttributes}
                        />
                      </>
                      )}

                      {/* ********** AUTOMATICO (PENDING) ********** */}
                      {loanRequest.saleCompletionAutomatic && disbursementStatus?.pending && (
                      <>
                        <OnboardingFinalAutomaticPending
                          loanRequest={loanRequest}
                          rechargeLoan={rechargeLoan}
                          servicePayLoan={servicePayLoan}
                          productAttributes={productAttributes}
                        />
                      </>
                      )}

                      {/* ********** AUTOMATICO (FAILED) ********** */}
                      {loanRequest.saleCompletionAutomatic && disbursementStatus?.failed && (
                      <>
                        <OnboardingFinalAutomaticFailed
                          loanRequest={loanRequest}
                          rechargeLoan={rechargeLoan}
                          servicePayLoan={servicePayLoan}
                          productAttributes={productAttributes}
                        />
                      </>
                      )}
                    </Grid>
                  </>
                  )}
                </>
              )}
            </>
          )}

          {/* ********** DISMISS LOAN ********** */}
          {notifyDismissActionMessage && (
          <>
            <Grid item xs={12} sm={8}>
              <OnboardingFinalDismiss />
            </Grid>
          </>
          )}

          {/* ********** BUTTON ********** */}
          {loanRequest && !ecommerceLoan && !showDismissConfirmation && !isLoanRequestRevolvingLine(loanRequest) && (
            <>
              <Grid item xs={12} sm={8}>
                <Button
                  variant="primary"
                  className="btn-primary"
                  type="button"
                  fullWidth
                  onClick={() => {
                    if (disbursementStatus?.success) {
                      goToLendingSelfie();
                    } else {
                      goToSite();
                    }
                  }}
                >
                  {disbursementStatus?.success ? i18n.OnboardingFinal.goLendingSelfie : i18n.OnboardingFinal.goHome }
                </Button>
                {allowDismiss && (
                <>
                  <Typography className="onboarding-final-dismiss-text" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {i18n.OnboardingFinal.dismissText}
                    <Button
                      variant="tertiary"
                      className="onboarding-final-dismiss-button"
                      onClick={() => { setShowDismissConfirmation(true); }}
                    >
                      {i18n.OnboardingFinal.linkCTA}
                    </Button>
                  </Typography>
                </>
                )}
              </Grid>
            </>
          )}

          {/* ********** SHOW DISMISS CONFIRMATION ********** */}
          {showDismissConfirmation && (
          <>
            <Grid container style={{ marginTop: '60px', marginBottom: '20px' }}>
              <Grid item>
                <Typography className="onboarding-final-title">
                  {fillMessageWith(i18n.OnboardingFinal.dismissConfirmationText,
                    i18n.WT_SUBPRODUCT_DESCRIPTION[loanRequest?.subProductId])}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '40px', marginBottom: '120px' }}>
              <Grid item xs={3} sm={2}>
                <img src={cancelCreditImg} alt="cancel-credit" width="55px" />
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography className="onboarding-final-subtitle-long">
                  {i18n.OnboardingFinal.dismissQuestionText}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={8} style={{ marginTop: '10px' }}>
                <Button
                  variant="secondary"
                  className="btn-primary"
                  type="button"
                  fullWidth
                  onClick={() => { dismissLoan(); }}
                >
                  {i18n.OnboardingFinal.dismissAnswerYes}
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} style={{ marginTop: '10px' }}>
                <Button
                  variant="primary"
                  className="btn-primary"
                  type="button"
                  fullWidth
                  onClick={() => { setShowDismissConfirmation(false); }}
                >
                  {i18n.OnboardingFinal.dismissAnswerNo}
                </Button>
              </Grid>
            </Grid>

          </>
          )}

        </Grid>

        {/* ********** ERROR ********** */}
        {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
        )}

        {/* ********** NPS ********** */}
        <Dialog
          open={showSurvey}
          onClose={() => setShowSurvey(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <NpsForm
            surveyInfo={surveyInfo}
            onFinish={onCloseSurvey}
          />
        </Dialog>

      </Box>

      <Box mt={3} />
      <SimpleFooter subproductId={loanRequest?.subProductId} />
    </>
  );
};

export default OnboardingFinal;
