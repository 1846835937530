import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Divider, Link } from '@doit/pcnt-react-ui-library';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';
import '../assets/css/onboardingHeader.css';
import { routes } from '../constants/routes';
import { useGlobalLoading } from '../contexts/globalLoadingContext';
import { isLoanRequestRevolvingLine } from '../utils/loanRequestUtils';

const OnboardingHeader = ({ loan, editable }) => {
  const isBNPL = () => loan.productToPay && loan.productToPay.ecommerceCartType;
  const { globalLoading } = useGlobalLoading();

  console.log(loan);

  if (isLoanRequestRevolvingLine(loan)) {
    return <></>;
  }

  return (
    <>
      {isBNPL() ? (
        <>
          {/* BNPL */}
          <Link
            className="onboarding-header-link"
            href={loan.productToPay?.products[0]?.productAttributes?.merchantReturnSuccessURL}
            startIcon={<ArrowBackIcon color="primary" />}
            size="medium"
          >
            {i18n.OnboardingHeader.returnStore}
          </Link>
          <Box mt={2} />
          <Typography className="onboarding-header">
            {parse(fillMessageWith(
              i18n.OnboardingHeader.bnpl,
              loan.customerName,
              loan.customerId,
              loan.productToPay?.products[0]?.productAttributes?.commerceName,
              moneyFormatterWithoutDecimals(loan.requestedAmount),
              fillMessageWith(
                loan.installmentsNumberSelected > 1
                  ? i18n.OnboardingHeader.installmentInfo[0] : i18n.OnboardingHeader.installmentInfo[1],
                loan.installmentsNumberSelected,
                moneyFormatterWithoutDecimals(loan.installmentAmountSelected),
              ),
            ))}
            {editable && !loan.loadDocumentsByCustomer && (
              <Link
                className="onboarding-header-link"
                href={`${routes.OnboardingInstallmentPlan}/${loan.hashKey}`}
                size="medium"
                disabled={globalLoading}
              >
                {i18n.OnboardingHeader.edit}
              </Link>
            )}
          </Typography>
        </>
      ) : (
        <>
          {/* CANCELACION */}
          {loan.response.prePaidLoan ? (
            <>
              <Typography className="onboarding-header">
                {parse(fillMessageWith(
                  i18n.OnboardingHeader.prepaid,
                  loan.customerName,
                  loan.customerId,
                  moneyFormatterWithoutDecimals(loan.requestedAmount),
                  moneyFormatterWithoutDecimals(loan.response.amountToDisburse),
                  fillMessageWith(
                    loan.installmentsNumberSelected > 1
                      ? i18n.OnboardingHeader.installmentInfo[0] : i18n.OnboardingHeader.installmentInfo[1],
                    loan.installmentsNumberSelected,
                    moneyFormatterWithoutDecimals(loan.installmentAmountSelected),
                  ),
                ))}
                {editable && !loan.loadDocumentsByCustomer && (
                  <Link
                    className="onboarding-header-link"
                    href={`${routes.OnboardingInstallmentPlan}/${loan.hashKey}`}
                    size="medium"
                    disabled={globalLoading}
                  >
                    {i18n.OnboardingHeader.edit}
                  </Link>
                )}
              </Typography>
            </>
          ) : (
            <Box>
              {/* NORMAL / DEFAULT */}
              <Typography className="onboarding-header">
                {parse(fillMessageWith(
                  i18n.OnboardingHeader.default,
                  loan.customerName,
                  loan.customerId,
                  moneyFormatterWithoutDecimals(loan.requestedAmount),
                  fillMessageWith(
                    loan.installmentsNumberSelected > 1
                      ? i18n.OnboardingHeader.installmentInfo[0] : i18n.OnboardingHeader.installmentInfo[1],
                    loan.installmentsNumberSelected,
                    moneyFormatterWithoutDecimals(loan.installmentAmountSelected),
                  ),
                ))}
                {editable && !loan.loadDocumentsByCustomer && (
                  <Link
                    className="onboarding-header-link"
                    href={`${routes.OnboardingInstallmentPlan}/${loan.hashKey}`}
                    size="medium"
                    disabled={globalLoading}
                  >
                    {i18n.OnboardingHeader.edit}
                  </Link>
                )}
              </Typography>
            </Box>
          )}
        </>
      )}
      <Box mt={2} />
      <Divider />
    </>
  );
};

OnboardingHeader.propTypes = {
  loan: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
};

OnboardingHeader.defaultProps = {
  editable: true,
};

export default OnboardingHeader;
