import parse from 'html-react-parser';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box, Grid, Hidden, Typography,
} from '@mui/material';
import DNIStep from '../components/DNIStep';
import {
  checkBlacklist, checkExistingLoanRequest, resolveCustomers, savePreSimulationLoan,
  sendVerificationCode, startWorkflow, updateCustomerData, updateVerifiedCodeAndEmail, verifyCode,
  verifyEmail,
  verifyEmailDomain,
} from '../api/onboardingService';
import { dniIncludesLastDigit, redirectToContinueURLSlashM, saveUrlSourceParameters } from '../utils/commonServices';
import {
  fillMessageWith, getResponseErrorOrDefault, sendDataLayerEventObject,
} from '../utils/functionsUtil';
import Toast from '../components/commons/Toast';
import IdentityResolverStep from '../components/IdentityResolverStep';
import EmailStep from '../components/EmailStep';
import CellphoneStep from '../components/CellphoneStep';
import { ERROR_CODES } from '../utils/errorCodeConstant';
import i18n from '../common/i18n';
import VerificationCodeStep from '../components/VerificationCodeStep';
import { loanRedirect } from '../components/LoanRequestRedirectService';
import { routes } from '../constants/routes';
import PreOnboardinLoadData from '../components/PreOnboardingLoadData';
import CalculatorLoading from '../components/commons/CalculatorLoading';
import ContinueLoanRequestModal from '../components/ContinueLoanRequestModal';
import SimpleFooter from '../components/SimpleFooter';
import { SUBPRODUCT_REVOLVING_LINE } from '../constants/onboardingConstants';

const PreOnboardingRevolvingLine = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const steps = {
    dni: 'dni',
    resolveCustomer: 'resolveCustomer',
    email: 'email',
    cellphone: 'cellphone',
    verificationCode: 'verificationCode',
  };

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.dni);
  const [customerList, setCustomerList] = useState();
  const [verificationCodeMethodEmail, setVerificationCodeMethodEmail] = useState(false);
  const [customerSelected, setCustomerSelected] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerCellphone, setCustomerCellphone] = useState();
  const [resendMessage, setResendMessage] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [prevHash, setPrevHash] = useState();
  const [calculatingOffer, setCalculatingOffer] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmitDNI = async (data) => {
    try {
      setLoading(true);
      const response = await resolveCustomers(data.dni);
      setCustomerList(response.data);
      setCurrentStep(steps.resolveCustomer);
      window.scrollTo(0, 0);
      setVerificationCodeMethodEmail(dniIncludesLastDigit(data.dni));
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIdentityResolverStep = (customer) => {
    setLoading(true);
    setCustomerSelected(customer);
    setLoading(false);
    setCurrentStep(steps.email);
    window.scrollTo(0, 0);
  };

  const handleChangeCustomer = () => {
    setCustomerSelected(undefined);
    setCustomerList(undefined);
    setCustomerEmail(undefined);
    setCustomerCellphone(undefined);
    setCurrentStep(steps.dni);
    window.scrollTo(0, 0);
  };

  const handleSubmitEmailStep = async ({ email }) => {
    try {
      setLoading(true);
      await verifyEmailDomain(email);
      await updateCustomerData(customerSelected, email, 94, undefined, undefined, 46);
      setCustomerEmail(email);
      setCurrentStep(steps.cellphone);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail = () => {
    setCustomerEmail(undefined);
    setCurrentStep(steps.email);
    window.scrollTo(0, 0);
  };

  const sendCode = async (cellphoneNumber, resendCode) => {
    try {
      const verificationInputValue = verificationCodeMethodEmail ? customerEmail : cellphoneNumber;
      sendDataLayerEventObject({ event: 'enviar_codigo', tipoValidacion: verificationCodeMethodEmail ? 'EMAIL' : 'SMS' });
      await sendVerificationCode(verificationInputValue, cellphoneNumber, customerEmail, resendCode);
      if (!resendCode) {
        await savePreSimulationLoan(cellphoneNumber, customerEmail, customerSelected, 'personal');
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_EMAIL_BELONG_TO_OTHER_CUSTOMER) {
        handleChangeEmail();
        throw error;
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_SMS_BUT_EMAIL_SENT) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageJustMail} ${customerEmail}`);
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_EMAIL) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageErrorMail}`);
        setInvalidEmail(true);
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const handleSubmitCellphone = async ({ cellphone, resend }) => {
    try {
      setLoading(true);

      const cellphoneNumber = Number(cellphone);

      await checkBlacklist(cellphoneNumber);
      await sendCode(cellphoneNumber, resend);

      setCustomerCellphone(cellphoneNumber);
      setCurrentStep(steps.verificationCode);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCellphone = () => {
    setCustomerCellphone(undefined);
    setCurrentStep(steps.cellphone);
    window.scrollTo(0, 0);
  };

  const resendVerificationCode = () => {
    handleSubmitCellphone({ cellphone: customerCellphone, resend: true });
    setResendMessage(
      fillMessageWith(i18n.VerificationCodeStep.resendMessage, (verificationCodeMethodEmail
        ? i18n.VerificationCodeStep.methodEmailLabel
        : i18n.VerificationCodeStep.methodSmsLabel)),
    );
  };

  const startWorkflowProcess = async () => {
    try {
      setCalculatingOffer(true);
      window.scrollTo(0, 0);
      const res = await startWorkflow();
      if (!verificationCodeMethodEmail) {
        await verifyEmail(res.data.hashKey);
      }
      saveUrlSourceParameters(res.data.hashKey);
      loanRedirect(res.data, navigate, queryParams.toString());
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE) {
        navigate({
          pathname: `${routes.OnboardingErrorCode}/${ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE}`,
          search: `?${queryParams.toString()}`,
        });
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const initWorkflow = async () => {
    try {
      const res = await checkExistingLoanRequest(customerSelected.cuit);
      setPrevHash(res.data.hashKey);
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SAPP_NO_PEN_CREDITS) {
        startWorkflowProcess();
      } else {
        setErrorMessage(i18n.unexpectedErrorMessage);
      }
    }
  };

  const handleSubmitVerificationCode = async ({ code }) => {
    try {
      setLoading(true);
      const data = {
        userInputName: 'WT_PHONE',
        userInputVerificationCode: code,
        userInputValue: verificationCodeMethodEmail
          ? customerEmail
          : customerCellphone,
      };
      const res = await verifyCode(data);
      if (res.data.result) {
        initWorkflow();
      } else {
        setErrorMessage(i18n.VerificationCodeStep.errors.invalidCode);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleContinueLoanRequest = async () => {
    try {
      await updateVerifiedCodeAndEmail(customerCellphone, customerEmail, prevHash);
      redirectToContinueURLSlashM(prevHash);
    } catch (error) {
      console.log(error);
    }
  };

  const reStartFlow = () => {
    setPrevHash(undefined);
    setCustomerSelected(undefined);
    setCustomerEmail(undefined);
    setCustomerCellphone(undefined);
    setCurrentStep(steps.dni);
  };

  return (
    <>
      {!calculatingOffer && (
        <Grid container className="preonboarding">
          <Grid item md={6} xs={12} className="preonboarding-flow">
            {/* ********** Muestra los datos que se fueron completando ********** */}
            <PreOnboardinLoadData
              customer={customerSelected}
              phone={customerCellphone}
              email={customerEmail}
            />
            {/* ********** Ingreso de DNI ********** */}
            {currentStep === steps.dni && (
              <>
                <Typography component="h1" className="display" align="left">
                  {parse(i18n.PreOnboardingRevolvingLine.title)}
                </Typography>
                <Typography align="left" className="subtitle">
                  {i18n.PreOnboardingRevolvingLine.description}
                </Typography>
                <DNIStep onSubmit={handleSubmitDNI} loading={loading} />
              </>
            )}
            {/* ********** Seleccion de CUIT ********** */}
            {currentStep === steps.resolveCustomer && (
              <IdentityResolverStep
                customers={customerList}
                onSubmit={handleSubmitIdentityResolverStep}
                goBack={handleChangeCustomer}
                loading={loading}
              />
            )}
            {/* ********** Ingreso de EMAIL ********** */}
            {currentStep === steps.email && (
              <EmailStep
                onSubmit={handleSubmitEmailStep}
                goBack={handleChangeCustomer}
                loading={loading}
              />
            )}
            {/* ********** Ingreso de Celular ********** */}
            {currentStep === steps.cellphone && (
              <CellphoneStep
                onSubmit={handleSubmitCellphone}
                goBack={handleChangeCustomer}
                currentCellphone={customerCellphone}
                loading={loading}
              />
            )}
            {/* ********** Verificacion codigo SMS/EMAIL ********** */}
            {currentStep === steps.verificationCode && (
              <VerificationCodeStep
                userInputLabel={
                  verificationCodeMethodEmail
                    ? i18n.VerificationCodeStep.methodEmailLabel
                    : i18n.VerificationCodeStep.methodSmsLabel
                }
                userInput={verificationCodeMethodEmail ? customerEmail : customerCellphone}
                onChangeUserInput={verificationCodeMethodEmail ? handleChangeEmail : handleChangeCellphone}
                onResend={resendVerificationCode}
                onSubmit={handleSubmitVerificationCode}
                loading={loading}
                resendMessage={resendMessage}
                onError={invalidEmail}
                goBack={handleChangeCustomer}
              />
            )}
          </Grid>
          <Hidden mdDown>
            <Grid item md={6}>
              <img src="/assets/images/banners/linea_credito.jpg" alt="LINEA_CREDITO" style={{ display: 'block' }} width="100%" />
            </Grid>
          </Hidden>
        </Grid>
      )}
      {/* ********** Loading calculadora ********** */}
      {calculatingOffer && (
        <>
          <CalculatorLoading />
          <Box mt={4} />
        </>
      )}
      <Grid container className="what-is" id="what-is">
        <Grid item md={12}>
          <Typography component="h3">{i18n.PreOnboardingRevolvingLine.whatIsTitle}</Typography>
          <Typography>{parse(i18n.PreOnboardingRevolvingLine.whatIsDescription)}</Typography>
          <ul>
            <li>
              <Typography>{i18n.PreOnboardingRevolvingLine.whatIsItems[0]}</Typography>
            </li>
            <li style={{ marginTop: '10px' }}>
              <Typography>{i18n.PreOnboardingRevolvingLine.whatIsItems[1]}</Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container className="next-steps">
        <Grid item md={12}>
          <Typography component="h4">{i18n.PreOnboardingRevolvingLine.nextStepTitle}</Typography>
          <Grid container gap={2}>
            {i18n.PreOnboardingRevolvingLine.nextSteps.map((step) => (
              <Grid
                item
                md={3.7}
                xs={12}
                key={step.description}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: {
                    xs: 'flex-start',
                    md: 'center',
                  },
                }}
              >
                <img src={step.image} alt="STEP" />
                <Typography>{step.description}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {/* ********** Modal de credito pendiente o finalizado ********** */}
      {prevHash && (
        <ContinueLoanRequestModal
          hash={prevHash}
          onClose={() => reStartFlow()}
          onContinue={handleContinueLoanRequest}
        />
      )}
      <SimpleFooter subproductId={SUBPRODUCT_REVOLVING_LINE} />
      {/* ********** Toast con mensajes de error ********** */}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
      )}
    </>
  );
};

export default PreOnboardingRevolvingLine;
