import { useEffect, useState } from 'react';
import { AppBar, Img } from '@doit/pcnt-react-ui-library';
import TagManager from 'react-gtm-module';
import logoCredicuotas from '../../assets/imgs/logo-credicuotas.svg';
import logoUber from '../../assets/imgs/logo-uber.svg';
import {
  getEnv, GOOGLE_TAG_MANAGER_ID,
} from '../../api/env';
import { getLoanRequest } from '../../api/onboardingService';

const AppBarWrapper = () => {
  const UBER = 'uber';
  const [logo, setLogo] = useState(logoCredicuotas);

  const resolveLogo = (partner) => {
    switch (partner.toLowerCase()) {
      case UBER: setLogo(logoUber); break;
      default: setLogo(logoCredicuotas); break;
    }
  };

  const resolvePartnerDataUsingHash = async () => {
    try {
      const hash = window.location.pathname.split('/')[2];
      if (hash && hash.startsWith('orig_')) {
        const { data } = await getLoanRequest(hash);
        if (data && data.partnerUserData && data.partnerUserData.partnerName) {
          resolveLogo(data.partnerUserData.partnerName);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = () => {
    if (window.location.pathname.includes(UBER)) {
      setLogo(logoUber);
    } else {
      resolvePartnerDataUsingHash();
    }
  };

  useEffect(() => {
    init();
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
  }, []);

  return (
    <>
      <AppBar
        logo={<Img alt="Credicuotas" src={logo} height={21} />}
        variant="dense"
        position="fixed"
      />
    </>
  );
};

export default AppBarWrapper;
