import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { getLoanRequest, reminder, saveMobileUserAgent } from '../api/onboardingService';
import { getResponseErrorOrDefault, isMobileOperatingSystem, sendDataLayerCustomerInfo } from '../utils/functionsUtil';
import Toast from '../components/commons/Toast';
import i18n from '../common/i18n';
import Greetings from '../components/InstallmentPlanGreetings';
import DualOffer from '../components/DualOffer';
import SimpleOffer from '../components/SimpleOffer';
import SimpleFooter from '../components/SimpleFooter';
import { loadWebchat } from '../components/webchatComponentScript';
import NotificationSubscription from '../components/NotificationSubscription';
import { hasPermissionGrantedOrDenied, requestPermission } from '../utils/firebase';
import RevolvingLineOffer from '../components/RevolvingLineOffer';
import { SUBPRODUCT_REVOLVING_LINE } from '../constants/onboardingConstants';

const OnboardingInstallmentPlan = () => {
  const { hash } = useParams();
  const [loanRequest, setLoanRequest] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showPushNotificationMessage, setShowPushNotificationMessage] = useState(false);
  const CUSTOME_MESSAGE_PUSH_NOTIFICATION_LIST = ['5', '6', '7', '8', '9'];

  const getPushNotificationMessage = () => {
    let message = i18n.NotificationSubscription.messageForSpecialBenefits;
    const dniLastDigit = loanRequest.customerId.slice(-2, -1);
    if (CUSTOME_MESSAGE_PUSH_NOTIFICATION_LIST.includes(dniLastDigit)) {
      message = i18n.NotificationSubscription.messageForSomeDNIs;
    }
    return message;
  };

  const handleCancelSubscription = () => {
    setShowPushNotificationMessage(false);
  };

  const handleSubscribe = () => {
    setShowPushNotificationMessage(false);
    requestPermission(hash);
  };

  const isPushNotificationOn = () =>
    isMobileOperatingSystem();

  const init = async () => {
    if (!hasPermissionGrantedOrDenied()) {
      setTimeout(() => {
        setShowPushNotificationMessage(true);
      }, 8000);
    }
    try {
      saveMobileUserAgent(hash, window.navigator.userAgent);
      await reminder(hash);
    } catch (error) {
      console.log(error);
    }
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      const lr = loanRequestResponse.data;
      sendDataLayerCustomerInfo(lr);
      setLoanRequest(lr);
      loadWebchat(lr);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {/* PopUp mensaje de subscripcion a notificaciones */}
      {isPushNotificationOn() && showPushNotificationMessage && (
        <NotificationSubscription
          onCancel={handleCancelSubscription}
          onSubscribe={handleSubscribe}
          message={getPushNotificationMessage()}
        />
      )}
      <Box className="main-container-body">
        <Grid container className="pre-onboarding-main-container">
          <Grid item xs={1} sm={1} md={1} />
          <Grid item xs={10} sm={10} md={10}>
            {loanRequest && (
              <>
                {loanRequest.subProductId === SUBPRODUCT_REVOLVING_LINE ? (
                  <RevolvingLineOffer loanRequest={loanRequest} />
                ) : (
                  <>
                    {/* ********** Componente saludo ********** */}
                    <Greetings loanRequest={loanRequest} />
                    <Box mt={2} />
                    {/* ********** Componente oferta ********** */}
                    {(!loanRequest.isOfferDual || (loanRequest.isOfferDual && loanRequest.installmentsNumberSelected)) && (
                      <SimpleOffer loanRequest={loanRequest} />
                    )}
                    {/* ********** Componente oferta dual ********** */}
                    {(loanRequest.isOfferDual && !loanRequest.installmentsNumberSelected) && (
                      <DualOffer loanRequest={loanRequest} />
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={() => setErrorMessage(undefined)}
          />
        )}
      </Box>
      <SimpleFooter subproductId={loanRequest?.subProductId} />
    </>
  );
};

export default OnboardingInstallmentPlan;
