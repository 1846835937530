import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Img, Link } from '@doit/pcnt-react-ui-library';
import {
  Box, Grid, Typography,
} from '@mui/material';
import benefitCupIcon from '../assets/imgs/benefitCup.svg';
import timeMoneyIcon from '../assets/imgs/timeMoney.svg';
import trophyIcon from '../assets/imgs/trophy.svg';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import { getMonthName, moneyFormatterWithoutDecimals } from '../utils/formatterUtil';

const BenefitBanner = ({ loanRequest, installments, selectedInstallment }) => {
  const shouldShowFirstInstallmentBenefit = () =>
    installments[0].firstInstallmentDiscountPercentage > 0
    && loanRequest.commerceLineCategory !== 'CendeuBIND';

  const shouldShowBenefitRate = () => loanRequest.commerceLineCategory === 'CendeuBIND';

  const shouldShowPostponeFirstInstallment = () => installments[0].postponeFirstInstallment;

  const showBenefitRate = () => (
    <Grid container className="benefit-rate-card">
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Grid container className="benefit-rate">
          <Grid item display="flex" alignItems="center">
            <Img src={benefitCupIcon} alt="Beneficio" width={60} />
            <Typography variant="h4">{i18n.BenefitBanner.rate.title}</Typography>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid container className="benefit-rate">
          <Grid item xs={12}>
            <Typography>
              {parse(i18n.BenefitBanner.rate.description1)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontStyle: 'italic', fontWeight: '600', color: '#F2025D' }}>
              {i18n.BenefitBanner.rate.description2}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const showFirstInstallmentBenefit = () => (
    <Grid container className="benefit-first-installment-card">
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Grid container className="benefit-first-installment">
          <Grid item display="flex" alignItems="center">
            <Img src={timeMoneyIcon} alt="Beneficio" width={60} />
            <Typography variant="h4" className="benefit-first-installment message">
              {selectedInstallment.firstInstallmentDiscountPercentage === 1 ? (
                <>
                  {parse(fillMessageWith(
                    i18n.BenefitBanner.firstInstallment.title[1],
                    moneyFormatterWithoutDecimals(selectedInstallment.maxDiscountAmount),
                  ))}
                </>
              ) : (
                <>
                  {parse(fillMessageWith(
                    i18n.BenefitBanner.firstInstallment.title[0],
                    selectedInstallment.firstInstallmentDiscountPercentage * 100,
                  ))}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid container className="benefit-first-installment">
          <Grid item xs={12}>
            <Typography>
              {parse(fillMessageWith(
                i18n.BenefitBanner.firstInstallment.description[0],
                moneyFormatterWithoutDecimals(selectedInstallment.firstInstallmentAmountWithDiscount),
              ))}
            </Typography>
            {selectedInstallment.firstInstallmentDiscountPercentage !== 1 && (
              <>
                <Typography>
                  {parse(fillMessageWith(
                    i18n.BenefitBanner.firstInstallment.description[1],
                    selectedInstallment.firstInstallmentDiscountPercentage * 100,
                    moneyFormatterWithoutDecimals(selectedInstallment.maxDiscountAmount),
                  ))}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Link href="https://rddrdge2q9.preview-postedstuff.com/V2-FeTN-5tQ6-R1zI-YRt0/" target="_blank" size="small">
              {i18n.BenefitBanner.firstInstallment.tyc}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const showPostponeFirstInstallment = () => (
    <Grid container className="postpone-first-installment-card">
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Grid container className="postpone-first-installment">
          <Grid item display="flex" alignItems="center">
            <Img src={trophyIcon} alt="Beneficio" width={60} />
            <Typography variant="h4">{i18n.BenefitBanner.postponeFirstInstallment.title}</Typography>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid container className="postpone-first-installment">
          <Grid item xs={12}>
            <Typography>
              <strong>{i18n.BenefitBanner.postponeFirstInstallment.description1}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {
                parse(
                  fillMessageWith(
                    i18n.BenefitBanner.postponeFirstInstallment.description2,
                    getMonthName(selectedInstallment.firstInstallmentDueDate),
                  ),
                )
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {/* ********** MENSAJES BENEFICIO PRIMERA CUOTA ********** */}
      {shouldShowFirstInstallmentBenefit() && (showFirstInstallmentBenefit())}
      {/* ********** MENSAJES DE BENEFICIO TASAS (CENDEU) ********** */}
      {shouldShowBenefitRate() && (showBenefitRate())}
      {/* ********** MENSAJES BENEFICIO PRIMERA CUOTA ********** */}
      {shouldShowPostponeFirstInstallment() && (showPostponeFirstInstallment())}
    </>
  );
};

BenefitBanner.propTypes = {
  loanRequest: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    isApproved: PropTypes.bool.isRequired,
    subProductId: PropTypes.number.isRequired,
    commerceLineCategory: PropTypes.string,
    customerInActiveCampaign: PropTypes.bool.isRequired,
    response: PropTypes.shape({
      prePaidLoan: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape({
    firstInstallmentDiscountPercentage: PropTypes.number.isRequired,
    postponeFirstInstallment: PropTypes.bool.isRequired,
  })).isRequired,
  selectedInstallment: PropTypes.shape({
    firstInstallmentDiscountPercentage: PropTypes.number.isRequired,
    firstInstallmentAmountWithDiscount: PropTypes.number.isRequired,
    firstInstallmentDueDate: PropTypes.number.isRequired,
    maxDiscountAmount: PropTypes.number.isRequired,
  }).isRequired,
};

export default BenefitBanner;
